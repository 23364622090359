import React, { useState, useEffect, useCallback } from 'react';
import { listTrafficLeads } from '../services/trafficLeads';
import { Users, RefreshCcw, Search, Filter, X } from 'lucide-react';
import { toast } from 'react-hot-toast';

const TrafficLeads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLeads, setTotalLeads] = useState(0);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const itemsPerPage = 10;

  const fetchLeads = useCallback(async () => {
    try {
      setLoading(true);
      const data = await listTrafficLeads(currentPage, itemsPerPage);
      setLeads(data.leads);
      setTotalPages(data.total_pages);
      setTotalLeads(data.total);
      toast.success('Leads atualizados com sucesso');
    } catch (error) {
      console.error('Erro ao carregar leads:', error);
      toast.error('Erro ao carregar leads');
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getLeadSource = (lead) => {
    const source = lead.metadata?.origin || lead.source || 'Desconhecido';
    // Traduzindo as origens para português
    switch (source.toLowerCase()) {
      case 'trafego':
        return 'Tráfego';
      case 'upload':
        return 'Carga';
      default:
        return 'Desconhecido';
    }
  };

  const getSourceColor = (source) => {
    switch (source.toLowerCase()) {
      case 'tráfego':
        return 'bg-blue-100 text-blue-800';
      case 'carga':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const filteredLeads = leads.filter(lead => {
    const matchesSearch = 
      lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.phone.includes(searchTerm) ||
      lead.cpf.includes(searchTerm);

    if (!activeFilter) return matchesSearch;
    
    const source = getLeadSource(lead);
    return matchesSearch && source.toLowerCase() === activeFilter.toLowerCase();
  });

  const formatCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatPhone = (phone) => {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#19bc5a]"></div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Users className="w-5 h-5 text-gray-500" />
          <h2 className="text-lg font-medium text-gray-900">Leads</h2>
        </div>
        <div className="flex items-center space-x-2">
          {/* Botão de Refresh */}
          <button
            onClick={fetchLeads}
            className="p-2 text-gray-500 hover:text-[#19bc5a] hover:bg-gray-100 rounded-full transition-colors"
            title="Atualizar leads"
          >
            <RefreshCcw className="w-5 h-5" />
          </button>

          {/* Botão de Filtro */}
          <div className="relative">
            <button
              onClick={() => setShowFilterMenu(!showFilterMenu)}
              className={`px-4 py-2 rounded-md transition-colors flex items-center space-x-2 ${
                activeFilter
                  ? 'bg-[#19bc5a] text-white hover:bg-[#148f44]'
                  : 'text-gray-700 border border-gray-300 hover:border-[#19bc5a] hover:text-[#19bc5a]'
              }`}
              title="Filtrar leads"
            >
              <Filter className="w-5 h-5" />
              <span className="text-sm font-medium whitespace-nowrap">
                {activeFilter ? `Origem: ${activeFilter === 'tráfego' ? 'Tráfego' : 'Carga'}` : 'Filtrar por Origem'}
              </span>
              {activeFilter && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveFilter(null);
                    setShowFilterMenu(false);
                  }}
                  className="ml-2 hover:text-gray-100"
                  title="Limpar filtro"
                >
                  <X className="w-4 h-4" />
                </button>
              )}
            </button>

            {/* Menu de Filtro */}
            {showFilterMenu && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                <div className="py-1">
                  <button
                    onClick={() => {
                      setActiveFilter(null);
                      setShowFilterMenu(false);
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between"
                  >
                    <span>Todos os Leads</span>
                    {!activeFilter && <span className="text-[#19bc5a]">✓</span>}
                  </button>
                  <button
                    onClick={() => {
                      setActiveFilter('tráfego');
                      setShowFilterMenu(false);
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between"
                  >
                    <span>Leads Trafego</span>
                    {activeFilter === 'tráfego' && <span className="text-[#19bc5a]">✓</span>}
                  </button>
                  <button
                    onClick={() => {
                      setActiveFilter('carga');
                      setShowFilterMenu(false);
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between"
                  >
                    <span>Leads de Carga</span>
                    {activeFilter === 'carga' && <span className="text-[#19bc5a]">✓</span>}
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Campo de Busca */}
          <div className="relative">
            <input
              type="text"
              placeholder="Buscar lead..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#19bc5a] focus:border-[#19bc5a]"
            />
            <Search className="w-4 h-4 text-gray-400 absolute left-2.5 top-3" />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-2.5 top-2.5 text-gray-400 hover:text-gray-600"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CPF</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CEP</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Origem</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredLeads.map((lead) => (
              <tr key={lead.session_id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">{lead.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lead.email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatPhone(lead.phone)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatCPF(lead.cpf)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {lead.zip_code.replace(/(\d{5})(\d{3})/, '$1-$2')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(lead.created_at)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getSourceColor(getLeadSource(lead))}`}>
                    {getLeadSource(lead)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    lead.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                  }`}>
                    {lead.status === 'active' ? 'Ativo' : 'Inativo'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-4">
        <div className="flex justify-center mt-4 space-x-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded ${
              currentPage === 1
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-[#19bc5a] text-white hover:bg-[#148f44]'
            }`}
          >
            Anterior
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === i + 1
                  ? 'bg-[#19bc5a] text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded ${
              currentPage === totalPages
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-[#19bc5a] text-white hover:bg-[#148f44]'
            }`}
          >
            Próxima
          </button>
        </div>
        <div className="text-center text-sm text-gray-500 mt-2">
          Total de {totalLeads} leads encontrados
        </div>
      </div>
    </div>
  );
};

export default TrafficLeads;
